<template>
  <b-card no-body>
    <b-overlay :show="isShow" rounded="lg" opacity="0.6">
      <b-card-body>
        <b-row>
          <b-col cols="10">
            <b-row>
              <b-col cols="2">
                <b-form-input type="text" v-model="_filter.product.name" placeholder="Ürün Adı (%%)"/>
              </b-col>
              <b-col cols="2">
                <b-form-input type="text" v-model="_filter.product.barcode" placeholder="Ürün Barkodu"/>
              </b-col>
              <b-col cols="2">
                <b-form-group label-for="brand">
                  <v-select
                      v-model="_filter.product.brandIds"
                      :options="_productBrands"
                      label="name"
                      :reduce="item => item.id"
                      input-id="brand"
                      multiple
                      placeholder="Markalar"
                  />
                </b-form-group>
              </b-col>


              <b-col cols="2">
                <b-form-group label-for="status">
                  <v-select
                      v-model="_filter.product.product_status_id"
                      :options="_productStatus"
                      label="name"
                      :reduce="item => item.id"
                      input-id="status"
                      placeholder="Durum"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label-for="brand">
                  <v-select
                      v-model="_filter.product.weights"
                      :options="[40,100,200,250,500,1000]"
                      input-id="brand"
                      multiple
                      placeholder="Gramajlar"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group>
                  <v-select
                      v-model="_filter.limit"
                      label="Limit"
                      :options="[10,25,50,100,200,500]"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <b-col cols="2">

            <div class="d-flex align-items-center justify-content-end">
              <b-row>
                <b-col cols="12">
                  <b-btn-group size="sm">
                    <b-btn variant="primary" @click="getPage(1)">Getir</b-btn>
                    <b-btn variant="primary">Temizle</b-btn>
                    <b-link class="btn btn-primary btn-sm" :to="{ name: 'product-add'}">
                      Ürün Ekle
                    </b-link>
                  </b-btn-group>
                </b-col>
                <b-col cols="12">
                  <v-select
                      v-model="_filter.product.isBundle"
                      label="text"
                      :options="isBundleOpt"
                      :reduce="item => item.value"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ProductFilter',
  components: {vSelect},
  directives: {Ripple},
  computed: {
    ...mapGetters('product', ['_products', '_filter', '_productBrands', '_productStatus']),
  },
  data: () => ({
    isShow: false,
    isBundleOpt: [
      {
        text: 'Tümü (Ürün/Paket)',
        value: 'all',
      },
      {
        text: 'Ürün',
        value: 'single',
      },
      {
        text: 'Paket',
        value: 'multi',
      },
    ],
  }),
  methods: {
    getPage(page) {
      this.isShow = true
      this._filter.page = page
      this.$store.dispatch('product/getPage')
          .then(res => {
            this.isShow = false
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
