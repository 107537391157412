<template>
  <div>
    <FilterView/>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>
    <b-card no-body class="mb-0">
      <ProductTable :products="_products"/>
    </b-card>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>
  </div>
</template>

<script>
import FilterView from '@/views/product/product/ProductFilter.vue'
import {mapGetters} from 'vuex'
import ProductTable from '@/views/product/product/ProductTable.vue'
import Pagination from "@/store/common/Pagination.vue";

export default {
  name: 'ProductHome',
  data: () => ({
    isShow: false,
  }),
  components: {
    Pagination,
    FilterView,
    ProductTable,
  },
  computed: {
    ...mapGetters('product', ['_products', '_pagination', '_filter']),
  },
  mounted() {
    this.$store.dispatch('product/getProductBrands')
    this.$store.dispatch('product/getProductStatus')
  },
  methods: {
    pageClick(page) {
      this.isShow = true
      this._filter.page = page
      this.$store.dispatch('product/getPage')
          .then(res => {
            this.isShow = false
          })
    }
  }
}
</script>

<style scoped>

</style>
